import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Modal } from 'antd';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUserInfo } from 'store/action/userInfos.js'
import { setUserCmsInfo } from 'store/action/userCmsInfos.js'
// import { fetchLoading } from 'store/action/loading.js'
import { cloneDeep } from 'lodash';
import { navigatorSource } from '@utils/navigator'

// import FetchLoading from "./components/FetchLoading"

import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';

import store from './store/index.js'

// import AuthLayout from 'layouts/auth';
// import UserLayout from 'layouts/user';

import AdminLayout from 'layouts/admin';
import AdminUserLayout from 'layouts/polymo';
// import UserManagement from './views/polymo/user-management';

// import RtlLayout from 'layouts/rtl';
import { ChakraProvider } from '@chakra-ui/react';
import theme from '@theme/theme';
// import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';

import SignInCentered from "views/auth/signIn";

import { getUserList } from 'api/index'

// import { isLogined } from './auth/index.js'
import '@/assets/css/App.css';
import '@theme/common.scss'

// const defRoute = {
// 	'admin': { path: '/admin', component: AdminLayout },
// 	'polymo': { path: '/polymo', component: AdminUserLayout }
// }

const IsLogin = () => {
	const history = useHistory();
	const userInfo = useSelector((state) => state.userInfos)
	const dispatch = useDispatch();
	const whitePage = [
		'/polymo/sign-in',
		'/polymo/sign-up', 
		'/cms/sign-in', 
		'/polymo/privacy', 
		'/polymo/disclaimers', 
		'/polymo/download', 
		'/polymo/mobile-home', 
		'/polymo/mobile-learnmore',
		'/polymo/mobile-download'
	];
	useEffect(() => {
		if (!whitePage.includes(window.location.pathname)) {
			if (!window.localStorage.getItem('token') && !window.localStorage.getItem('cmstoken')) {
				if (window.location.href.includes('cms')) {
					history.push('/cms/sign-in')
				} else {
					history.push('/polymo/home')
				}
			}
		}
		if (window.localStorage.getItem('token') && window.location.href.includes('polymo') && !whitePage.includes(window.location.pathname)) {
			getUserList('/app/fb/user').then((res) => {
				window.localStorage.setItem('isSignIn', '1');
				if (res && res.code === 200) {
					dispatch(setUserInfo({}));
					window.localStorage.removeItem('account');
					window.localStorage.removeItem('account_email');
					window.localStorage.removeItem('userRole');
					if (res.data.account_role === 255) {
						history.push('/polymo/sign-in')
					} else {
						if(res.data?.account_id) {
							window.localStorage.setItem('account', res.data.account_id)
						}
						if(res.data?.email) {
							window.localStorage.setItem('account_email', res.data.email)
						}
						if(res.data?.account_role) {
							window.localStorage.setItem('userRole', res.data.account_role)
						}
					}
					let selfData = {}
					const cloneData = cloneDeep(res.data);
					if (window.localStorage.getItem('general_phone')) {
						selfData = { ...cloneData, phone_number: window.localStorage.getItem('general_phone') || '' }
					} else {
						selfData = { ...cloneData }
					}
					dispatch(setUserInfo(selfData));
				} else {
					history.push('/polymo/home')
				}
			})
		} else if (window.localStorage.getItem('cmstoken') && window.location.href.includes('cms') && !whitePage.includes(window.location.pathname)) {
			getUserList('/app/fb/user').then((res) => {
				if (res && res.data) {
					dispatch(setUserCmsInfo({}));
					if (res.data.account_role === 255) {
						dispatch(setUserCmsInfo(res.data));
					} else {
						history.push('/cms/sign-in')
					}
				}
			})
		} else if(!whitePage.includes(window.location.pathname)) {
			if (window.location.href.includes('cms')) {
				history.push('/cms/sign-in')
			} else {
				if(navigatorSource() === 'PC') {
					// 增加判断输入路径进入about
					if(window.location.href.includes('privacy')) {
						return
					}
					history.push('/polymo/home')
				} else {
					if(navigatorSource() === 'PC') {
						history.push('/polymo/sign-in')
					} else {
						history.push('/polymo/mobile-home')
					}
				}
			}
		}


	}, []);
	useEffect(() => {
		window.addEventListener('visibilitychange', function () {
			if (document.visibilityState === 'visible') {
				if (!whitePage.includes(window.location.pathname)) {
					if (window.location.href.includes('polymo') && userInfo.email && window.localStorage.getItem('account_email')) {
						if (window.localStorage.getItem('account_email') !== userInfo.email) {
							Modal.destroyAll();
							Modal.warning({
								title: 'Warning',
								content: (
									<div>
										<p>You were taken offline due to other user logging in on this machine. Please log in again.</p>
									</div>
								),
								onOk() {
									Modal.destroyAll();
									history.push('/polymo/sign-in');
									window.location.reload();
								},
							});
						}
					} else if (window.location.href.includes('cms') && userInfo.email && window.localStorage.getItem('account_cms_email')) {
						if (window.localStorage.getItem('account_cms_email') !== userInfo.email) {
							Modal.destroyAll();
							Modal.warning({
								title: 'Warning',
								content: (
									<div>
										<p>You were taken offline due to other user logging in on this machine. Please log in again.</p>
									</div>
								),
								onOk() {
									Modal.destroyAll();
									history.push('/cms/sign-in');
									window.location.reload();
								},
							});
						}
					}
				}
			}
		})
	}, [userInfo.email]);
	// const login = isLogined();
	// // 根据登录用户显示进入对应路由页面
	// const role = window.localStorage.getItem('userRole') === 'user' ? 'polymo' : 'admin';
	return <Switch>
		<Route path={`/cms/sign-in`} component={SignInCentered} />
		<Route path={`/cms`} component={AdminLayout} />
		<Route path={'/polymo'} component={AdminUserLayout} />
		<Redirect from='/' to={'/polymo'} />
	</Switch>
}

ReactDOM.render(
	<ChakraProvider theme={theme}>
		<Provider store={store()}>
			<React.StrictMode>
				<BrowserRouter>
					<IsLogin />
				</BrowserRouter>
			</React.StrictMode>
		</Provider>
	</ChakraProvider>,
	document.getElementById('root')
);
