import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
// import { useSelector } from 'react-redux';
import { ArrowBackIcon } from '@chakra-ui/icons'
import './index.scss'
import androidJpg from 'assets/img/android.jpg'
import iosJpg from 'assets/img/ios.jpg'

const PolymoCampaignHome = () => {
  const history = useHistory();
  const [downloadData] = useState(
    [
      {
        title: 'Ios',
        img: iosJpg,
      },
      {
        title: 'Android',
        img: androidJpg,
      },
    ]
  )

  // 返回
  const onGoHome = () => {
    history.go(-1);
  }
  
  return (
    <div className="polyfund-campaign-learn-mobile-page">
      <div className="polyfund-campaign-mobile-main">
        <div className="header">
          <div>
            PolymoAI
          </div>
          <div onClick={onGoHome}>
            <ArrowBackIcon color="#fff"  />
          </div>
        </div>
        <div>
          {
            downloadData?.map((item, index) => {
              return <div key={index} className="downloadItem cardItem">
              {
                item.title ?
                  <div className="title">{item.title}</div> : null
              }
              {
                item.img ?
                <img className="img" src={item.img} alt="" /> : null
              }
              </div>
            })
          }
        </div>
      </div>

    </div>
  );
}
export default PolymoCampaignHome
