import React, { useState } from 'react';
import { Box, useDisclosure } from '@chakra-ui/react';
// import Footer from 'components/footer/FooterAdmin.js';
// Layout components
import Navbar from 'components/navbar/NavbarAdmin.js';
import { Redirect, Route, Switch } from 'react-router-dom';
import defRoutes from '@/routes.js';
import '@/assets/css/polymo.scss'
import { useHistory } from 'react-router-dom';

// Custom Chakra theme
export default function Dashboard(props) {
	const history = useHistory();

	const allowRoute = [11];
	const routes = defRoutes.filter((item, key) => allowRoute.includes(item.key))
	// const routes = defRoutes;
	const { ...rest } = props;
	// states and functions
	const [fixed] = useState(false);
	// const [toggleSidebar, setToggleSidebar] = useState(false);
	// functions for changing the states from components
	const getRoute = () => {
		return window.location.pathname !== '/admin/full-screen-maps';
	};
	const getActiveRoute = (routes) => {
		let activeRoute = 'Default Brand Text';
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveRoute = getActiveRoute(routes[i].items);
				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute;
				}
			} else if (routes[i].category) {
				let categoryActiveRoute = getActiveRoute(routes[i].items);
				if (categoryActiveRoute !== activeRoute) {
					return categoryActiveRoute;
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					return routes[i].name;
				}
			}
		}
		return activeRoute;
	};
	const getActiveNavbar = (routes) => {
		let activeNavbar = false;
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveNavbar = getActiveNavbar(routes[i].items);
				if (collapseActiveNavbar !== activeNavbar) {
					return collapseActiveNavbar;
				}
			} else if (routes[i].category) {
				let categoryActiveNavbar = getActiveNavbar(routes[i].items);
				if (categoryActiveNavbar !== activeNavbar) {
					return categoryActiveNavbar;
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					return routes[i].secondary;
				}
			}
		}
		return activeNavbar;
	};
	const getActiveNavbarText = (routes) => {
		let activeNavbar = false;
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveNavbar = getActiveNavbarText(routes[i].items);
				if (collapseActiveNavbar !== activeNavbar) {
					return collapseActiveNavbar;
				}
			} else if (routes[i].category) {
				let categoryActiveNavbar = getActiveNavbarText(routes[i].items);
				if (categoryActiveNavbar !== activeNavbar) {
					return categoryActiveNavbar;
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					return routes[i].messageNavbar;
				}
			}
		}
		return activeNavbar;
	};
	const getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.layout === '/polymo') {
				return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
			}
			if (prop.collapse) {
				return getRoutes(prop.items);
			}
			if (prop.category) {
				return getRoutes(prop.items);
			} else {
				return null;
			}
		});
	};
	document.documentElement.dir = 'ltr';
	const { onOpen } = useDisclosure();
	document.documentElement.dir = 'ltr';

	const onGoLink = () => {
		history.push('/polymo/disclaimers', '_self')
	}

	const hideBarUrlList = ['/polymo/mobile-home', '/polymo/mobile-learnmore', '/polymo/mobile-download']
	return (
		<div className='polymofund-main'>
			{
				!hideBarUrlList.includes(window.location.pathname) ? 
				<Navbar
					onOpen={onOpen}
					logoText={'Horizon UI Dashboard PRO'}
					brandText={getActiveRoute(routes)}
					secondary={getActiveNavbar(routes)}
					message={getActiveNavbarText(routes)}
					fixed={fixed}
					{...rest}
				/> : null
			}
			{getRoute() ? (
				<Box pt={{ sm: '0px', lg: '93px' }} className="polymofund-content">
					<Switch>
						{getRoutes(routes)}
						{
							window.location.href.includes('privacy') ? <Redirect from='/' to='/polymo/privacy' /> : <Redirect from='/' to='/polymo/home' />
						}
					</Switch>
				</Box>
			) : null}
			<div className="polymofund-footer">
				© 2024 All Rights Reserved, PolymoAI
				<span onClick={onGoLink} className="polymofund-footer-link">Disclaimers</span>
			</div>
		</div>
	);
}
