import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
// import { useSelector } from 'react-redux';
import { ArrowBackIcon } from '@chakra-ui/icons'
import './index.scss'

const PolymoCampaignHome = () => {
  const history = useHistory();
  const [fundData] = useState([
    {
      title: 'Extensive Database',
      des: '200,000+ active donors in North Carolina and beyond.'
    },
    {
      title: 'Rich Insights',
      des: 'Donor preferences, donation history, and more.'
    },
    {
      title: 'Always Updated',
      des: 'Monthly database refresh for current data.'
    },
    {
      title: 'Seamless Access',
      des: 'Web and mobile apps for easy engagement by multiple staffers simultaneously.'
    },
    {
      title: 'Decision Support',
      des: 'Al-driven recommendations for maximum impact.'
    }
  ])
  const [campaignData] = useState([
    {
      title: 'Revolutionary Tool',
      des: 'Efficient voter engagement at your fingertips.'
    },
    {
      title: 'Growing Network',
      des: 'Lightning-fast message distribution focused on the most receptive audiences.'
    },
    {
      title: 'Confident Outreach',
      des: 'Early insights into highest-potential voters and supporters.'
    }
  ])

  // 返回
  const onGoHome = () => {
    history.go(-1);
  }
  
  return (
    <div className="polyfund-campaign-learn-mobile-page">
      <div className="polyfund-campaign-mobile-main">
        <div className="header">
          <div>
            {history.location.state.key}
          </div>
          <div onClick={onGoHome}>
            <ArrowBackIcon color="#fff"  />
          </div>
        </div>

        {
          history.location.state.key === 'PolymoFund' ?
          <div className="fundDes">Setup on computer, engage donors on the go.</div> : null
        }
        <div className="cardItemLearn">
          {
            (history.location.state.key === 'PolymoFund' ? fundData : campaignData)?.map((item, index) => {
              return <div key={index} >
                <div className="title">
                  {item.title}
                </div>
                <div className="des">
                  {item.des}
                </div>
              </div>
            })
          }
        </div>
      </div>

    </div>
  );
}
export default PolymoCampaignHome
