import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import DefaultButton from 'components/button'
import './index.scss'

const PolymoCampaignHome = () => {
  const history = useHistory();

  const [teamData] = useState([
    {
      title: 'PolymoFund',
      key: 'PolymoFund',
      des: 'Powering Donor Engagement'
    },
    {
      title: 'PolymoCampaign',
      key: 'PolymoCampaign',
      des: 'Engage with Confidence'
    }
  ])

  const onGoDownload = () => {
    history.push('/polymo/mobile-download');

  }
  const onGoLearnMore = (item) => {
    history.push('/polymo/mobile-learnmore', { key: item.key });
  }
  return (
    <div className="polyfund-campaign-mobile-page">
      <div className="polyfund-campaign-mobile-main">
        <div className="header">
          PolymoAI
        </div>
        <div className="desc">
          <div className='text'>
            Supercharge your campaign AND WIN!
          </div>
          <div className='text textT'>
            Join us in shaping the future of political engagement.
          </div>
        </div>
        <div>
          {
            teamData?.map((item, index) => {
              return <div key={index} className="cardItem">
                <div className="title">
                  {item.title}
                </div>
                <div className="des">
                  {item.des}
                </div>
                <div className="bottomContent">
                    {
                      item.key === 'PolymoCampaign' ?  <DefaultButton
                      onClick={onGoDownload}
                      name='Download'
                      width="162px"
                      borderRadius="20px"
                    ></DefaultButton> : <div></div>
                    }
                  
                  <div className="learnMore" onClick={() => onGoLearnMore(item)}> 
                    Learn more
                  </div>
                </div>
              </div>
            })

          }
        </div>
      </div>

    </div>
  );
}
export default PolymoCampaignHome
